import styles from "./Help.module.css";
import DeliveryPic from "../images/deliveryPic.webp";
import ActionAnchorButton from "../components/ActionAnchorButton";
import Logobar from "../components/Logobar";
import HeadingTitle from "../components/HeadingTitle";
import Footer from "../components/Footer";
import { CheckIcon } from "@heroicons/react/24/solid";

const Help = () => {
  return (
    <>
      <div className="section">
        <HeadingTitle title="Welcome to GO-PHOR" />

        <Logobar />
        <div className="container">
          <div>
            <div className={styles.intro}>
              <p>
                Please browse the help material below. For more information
                email us at:
                <span>
                  <a href="mailto::info@go-phor.com">info@go-phor.com</a>
                </span>
              </p>
            </div>
          </div>
          {/* a 2 column area to display links */}
          <section className={styles["link-section"]}>
            {/* first column of links */}
            <div className={styles["link-item"]}>
              <div className={styles["learn-more"]}>
                <div className={styles.subheading}>
                  Click here for more information about GO-PHOR!
                </div>
                <ActionAnchorButton
                  href="https://go-phor.com"
                  target="_blank"
                  text="Learn More &rarr;"
                />
              </div>
              <div className={styles.legal}>
                <div className={styles.subheading}>Legal Information</div>
                <ul>
                  <li className={styles["legal-link"]}>
                    <CheckIcon className={styles.icon}></CheckIcon>
                    <a href="https://go-phor.com/t-s-and-c-s" target="_blank">
                      Terms & Conditions
                    </a>
                  </li>
                  <li className={styles["legal-link"]}>
                    <CheckIcon className={styles.icon}></CheckIcon>
                    <a href="https://go-phor.com/privacypolicy" target="_blank">
                      Privacy Policy
                    </a>
                  </li>
                  <li className={styles["legal-link"]}>
                    <CheckIcon className={styles.icon}></CheckIcon>
                    <a href="https://stripe.com/legal/ssa" target="_blank">
                      Stripe Service Agreement
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {/* second column of links */}
            <div className={styles["link-item"]}>
              <img className={styles["link-img"]} src={DeliveryPic} />
            </div>
          </section>

          {/* youtube videos formatted in another grid area */}
          {/* <div className={styles.youtube}>
          YouTube videos with information on using the phone app:
        </div>
        <section className={styles["video-links"]}>
          <div className={styles["video-item"]}>
            <iframe
              width="445"
              height="250"
              src="https://www.youtube.com/embed/yTNLyD44yLc"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className={styles["video-item"]}>
            <iframe
              width="445"
              height="250"
              src="https://www.youtube.com/embed/3lubjr7EW1E"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className={styles["video-item"]}>
            <iframe
              width="445"
              height="250"
              src="https://www.youtube.com/embed/XuLZyEXtGms"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className={styles["video-item"]}>
            <iframe
              width="445"
              height="250"
              src="https://www.youtube.com/embed/kiS5P4MOH4E"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </section> */}
        </div>
        <Footer />
      </div>
    </>
  );
};
export default Help;
