import NavbarButtonComponent from "./NavbarButtonComponent";
import { useLogout } from "../hooks/useLogout";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import styles from "./NavbarComponent.module.css";
import Bars3Icon from "@heroicons/react/24/solid/Bars3Icon";
import XMarkIcon from "@heroicons/react/24/solid/XMarkIcon";
import Logo from "../images/go-phor-logo-withframe.webp";

const NavbarComponent = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const { user } = useAuthContext();
  const { logout } = useLogout();
  const navigate = useNavigate();
  const handleBtnClick = () => {
    setIsOpen(!isOpen);
    setIsClicked(false);
  };

  const handleClick = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  const handleProfile = () => {
    navigate("/profile");
  };
  const handleLogout = () => {
    logout();
  };

  return (
    <section className={styles["navbar-container"]}>
      {/* navbar-section contains the brand area and the menu area */}
      <nav
        className={`${styles["navbar-section"]} ${
          isOpen ? `${styles.open}` : ""
        }`}
      >
        {/* section with logo and company title */}
        <div className={styles["nav-brand-area"]}>
          <Link to="/">
            <img className={styles["img-logo"]} src={Logo} alt="go-phor-logo" />
          </Link>
          <Link to="/" className={styles["nav-brand-name"]}>
            GO-PHOR
          </Link>
        </div>

        {/* section with buttons */}
        {/* icon for when screen is smaller*/}
        <div className={styles.navmenu}>
          <div className={styles["mobile-button-area"]}>
            <button onClick={handleBtnClick}>
              <Bars3Icon
                className={`${styles.icon} ${styles["menu-btn"]} ${
                  isOpen ? `${styles.open}` : ""
                } `}
              ></Bars3Icon>{" "}
            </button>
            <button onClick={handleBtnClick}>
              <XMarkIcon
                className={`${styles.icon} ${styles["close-btn"]}  ${
                  isOpen ? `${styles.open}` : ""
                } `}
              ></XMarkIcon>
            </button>
          </div>
          <div
            onClick={handleClick}
            className={`${styles["button-area"]} ${
              isOpen ? `${styles.open}` : ""
            } `}
          >
            {!user && <NavbarButtonComponent type="Link" to="/" text="Home" />}
            {!user && (
              <NavbarButtonComponent type="Link" to="/signup" text="Sign up" />
            )}
            {!user && (
              <NavbarButtonComponent type="Link" to="/login" text="Log in" />
            )}
            {user && (
              <NavbarButtonComponent
                type="Link"
                to="/dashboard"
                text="Dashboard"
              />
            )}
            {user && (
              <NavbarButtonComponent
                type="Link"
                to="/paymentmethod"
                text="Payment"
              />
            )}
            {user && (
              <NavbarButtonComponent
                type="Link"
                to="/savedlocations"
                text="Locations"
              />
            )}
            {user && (
              <NavbarButtonComponent
                logout={handleLogout}
                type="Logout"
                to="/"
                text="Log out"
              />
            )}

            <NavbarButtonComponent type="Link" to="/help" text="Help" />

            {user && user.firstName && user.lastName && (
              <div className={styles["welcome-msg"]}>
                <div className={styles.letter} onClick={handleProfile}>
                  {user.firstName[0]}
                  {user.lastName[0]}
                </div>
                <h1>Welcome</h1>
                <h1>
                  {user.firstName} {user.lastName}
                </h1>
              </div>
            )}
          </div>
        </div>
      </nav>
    </section>
  );
};

export default NavbarComponent;
