import styles from "./ActionAnchorButton.module.css";

// {/* <div className={`${baseStyles["link-area-styles"]} ${styles["anchor-style"]}`} */}
//       {/* <div className={`${baseStyles["link-styles"]} ${styles["anchor-text"]}`}> */}

const ActionAnchorButton = ({ href, text }) => {
  return (
    <div className={styles["anchor-style"]}>
      <div className={styles["anchor-text"]}>
        <a href={href} target="_blank">
          {text}
        </a>
      </div>
    </div>
  );
};
export default ActionAnchorButton;
