import { useState } from "react";
import { usePaymentMethodDelete } from "../hooks/usePaymentMethodDelete";
import ButtonStyleComponent from "./ButtonStyleComponent";
import styles from "./PaymentMethodItem.module.css";

const PaymentMethodItem = ({ data, onDelete }) => {
  const [deletePending, setDeletePending] = useState(false);
  const [deleteAcct, setDeleteAcct] = useState(false);
  const { deletePaymentMethod, isPending, error } = usePaymentMethodDelete(
    data.id
  );

  const handleDelete = () => {
    setDeleteAcct(true);
    setDeletePending(false);
  };
  const handleCancel = () => {
    setDeleteAcct(false);
  };
  const finishDelete = async (e) => {
    setDeletePending(true);
    const deleteError = await deletePaymentMethod();
    if (deleteError) {
    } else {
      onDelete();
    }
  };

  return (
    <div key={data.id} className={styles["payment-card"]}>
      {data.type === "card" && (
        <div>
          <h1>Credit Card:</h1>
          <div className={styles["payment-grid"]}>
            <p>
              {data.brandName} ending in {data.last4Digits}, expiring on{" "}
              {data.expireMonth}/{data.expireYear}
            </p>
            <div className={styles["delete-icon"]} onClick={handleDelete}></div>
          </div>

          {error && deletePending && (
            <div className="errorMsg">
              <p>
                Could not delete payment method at this time. Please try again.
              </p>
            </div>
          )}
          {deleteAcct && !deletePending && (
            <>
              <div className="errorMsg">
                <p>
                  Do you really wish to delete the credit card ending in{" "}
                  {data.last4Digits}?
                </p>
              </div>

              <div className={styles["button-area"]}>
                <ButtonStyleComponent
                  buttonName="Delete"
                  handleButton={finishDelete}
                />
                <ButtonStyleComponent
                  buttonName="Cancel"
                  handleButton={handleCancel}
                />
              </div>
            </>
          )}
          {deletePending && deleteAcct && !error && (
            <div className="pending">
              <p>Deleting payment method...</p>
            </div>
          )}
        </div>
      )}
      {data.type === "us_bank_account" && (
        <div>
          <h1>Bank Account:</h1>
          <div className={styles["payment-grid"]}>
            <p>
              {data.accountType} ending in {data.last4Digits}
            </p>
            <div className={styles["delete-icon"]} onClick={handleDelete}></div>
          </div>
          {error && deletePending && (
            <div className="errorMsg">
              <p>Could not delete payment method at this time.</p>
              <p>Please try again.</p>
            </div>
          )}

          {deleteAcct && !deletePending && (
            <>
              <div className="errorMsg">
                <p>
                  Do you really wish to delete the bank account ending in{" "}
                  {data.last4Digits}?
                </p>
              </div>

              <div className={styles["button-area"]}>
                <ButtonStyleComponent
                  buttonName="Delete"
                  handleButton={finishDelete}
                />
                <ButtonStyleComponent
                  buttonName="Cancel"
                  handleButton={handleCancel}
                />
              </div>
            </>
          )}
          {deletePending && deleteAcct && !error && (
            <div className="pending">
              <p>Deleting payment method...</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default PaymentMethodItem;
