import HeadingTitle from "../components/HeadingTitle";
import styles from "./Profile.module.css";
import { format } from "date-fns";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";
import PersonIcon from "../images/personIcon.svg";
import ModalWindow from "../components/ModalWindow";
import ButtonStyleComponent from "../components/ButtonStyleComponent";
import Footer from "../components/Footer";

const Profile = () => {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const joined = format(new Date(user.createdAt), "MMMM yyyy");

  const handleClick = (e) => {
    e.preventDefault();
    navigate("/editprofile");
  };
  const handleCancel = () => {
    navigate("/dashboard");
  };

  return (
    <>
      <div className={styles["profile-section"]}>
        <HeadingTitle title="Manage Profile Information" />
        <ModalWindow>
          <div className="container">
            {!user.imageURL && (
              <img src={PersonIcon} className={styles.photo}></img>
            )}
            {user.imageURL && (
              <img
                src={user.imageURL}
                className={styles["photo-img"]}
                alt="profile image"
              ></img>
            )}
            <div className={styles["profile-content"]}>
              <h2>
                {user.firstName} {user.lastName}
              </h2>
              <h4>Email: {user.email}</h4>
              <h4>Phone Number: {user.phoneNumber}</h4>
              <h4>Joined: {joined}</h4>
            </div>
            <div className={styles["button-section"]}>
              <div className={styles["button-item"]}>
                <ButtonStyleComponent
                  buttonName="Edit Profile"
                  handleButton={handleClick}
                  buttonClass="profile-btn"
                />
                <ButtonStyleComponent
                  buttonName="Cancel"
                  handleButton={handleCancel}
                  buttonClass="cancel-btn"
                />
              </div>
            </div>
          </div>
        </ModalWindow>
      </div>
      <Footer />
    </>
  );
};
export default Profile;
