import { useRef, useEffect, useState } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import { useSavedLocation } from "../hooks/useSavedLocation";
import { useNavigate } from "react-router-dom";
import "./AddSavedLocation.css";
import HeadingTitle from "../components/HeadingTitle";
import ButtonStyleComponent from "../components/ButtonStyleComponent";
import Footer from "../components/Footer";

const AddSavedLocation = () => {
  const { user } = useAuthContext();
  const { addNewSavedLocation } = useSavedLocation();
  const navigate = useNavigate();
  let addressText = "";
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const [error, setError] = useState("");
  const [pending, setPending] = useState(false);
  const options = {
    fields: [
      "address_components",
      "formatted_address",
      "geometry",
      "icon",
      "name",
    ],
  };
  const handleCancel = () => {
    navigate("/savedlocations");
  };

  const handleAdd = async (place) => {
    setPending(true);
    const nameLength = place.name.length;
    // if name of location exists
    if (nameLength > 0) {
      const subString = place.formatted_address.slice(0, nameLength);
      // if the formatted address contains the name of the location, then leave name of location as is
      // otherwise, add the name of location to the formatted address;

      if (place.name === subString) {
        addressText = place.formatted_address;
      } else {
        addressText = place.name + " " + place.formatted_address;
      }
    }

    const locationName = place.name;
    const latitude = place.geometry.location.lat();
    const longitude = place.geometry.location.lng();
    try {
      const id = await addNewSavedLocation(
        locationName,
        addressText,
        latitude,
        longitude
      );
      setPending(false);
      setError(false);
      navigate("/editsavedlocation", {
        state: {
          user: user.uid,
          id,
          locationName,
          locationAddress: addressText,
        },
      });
    } catch (err) {
      setPending(false);
      setError("Unable to add new saved location");
    }
  };

  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );
    autoCompleteRef.current.addListener("place_changed", async function () {
      const place = await autoCompleteRef.current.getPlace();
      handleAdd(place);
    });
  }, []);

  return (
    <>
      <div className="section">
        <HeadingTitle title="Add New Saved Location" />
        <div className="maparea">
          {pending && !error && (
            <div className="pending">
              <p>Adding new saved location...</p>
            </div>
          )}
          {error && (
            <div className="errorMsg">
              <p>{error}</p>
            </div>
          )}
          {!pending && (
            <>
              <div className="location-choice">
                <p>
                  Start typing a name or location and choose a location from the
                  list.
                </p>
                <input ref={inputRef} />
              </div>
              <ButtonStyleComponent
                buttonName="Cancel"
                buttonClass="cancel-btn"
                handleButton={handleCancel}
              />
            </>
          )}
        </div>
        <Footer />
      </div>
    </>
  );
};
export default AddSavedLocation;
