import { fireDB, cloudFuncs } from "../firebase/config";
import { httpsCallable } from "firebase/functions";
import { doc, deleteDoc, setDoc } from "firebase/firestore";

export const useSavedLocation = () => {
  const deleteSavedLocation = async (id, user) => {
    const docRef = doc(fireDB, "users", user, "locations", id);
    await deleteDoc(docRef);
  };

  const addNewSavedLocation = async (
    locationName,
    addressText,
    latitude,
    longitude
  ) => {
    const fn = httpsCallable(cloudFuncs, "saveNewLocation");
    const response = await fn({
      locationName,
      addressText,
      latitude,
      longitude,
    });
    const id = response.data.locationUID;

    return id;
  };

  const editSavedLocation = async (id, user, addressText, locationName) => {
    const docRef = doc(fireDB, "users", user, "locations", id);
    const curDateTime = new Date();
    const objVariable = {
      updatedAt: curDateTime,
      addressText,
      locationName,
    };
    await setDoc(docRef, objVariable, { merge: true });
  };
  return { deleteSavedLocation, editSavedLocation, addNewSavedLocation };
};
