import ActionAnchorButton from "../components/ActionAnchorButton";
import Logobar from "../components/Logobar";
import HeadingTitle from "../components/HeadingTitle";
import styles from "./Home.module.css";
import DeliveryPic from "../images/delPic2.webp";
import ButtonStyleComponent from "../components/ButtonStyleComponent";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
const Home = () => {
  const navigate = useNavigate();
  const login = () => {
    navigate("/login");
  };
  const signup = () => {
    navigate("/signup");
  };
  return (
    <>
      <div className="section">
        <HeadingTitle title="Welcome to GO-PHOR" />

        <Logobar />
        <div className="container">
          {/* a 2 column area to display links */}
          <section className={styles["link-section"]}>
            {/* first column of with a picture */}
            <div className={styles["link-item"]}>
              <img className={styles["link-img"]} src={DeliveryPic} />
            </div>

            {/* second column of links */}
            <div className={styles["link-item"]}>
              <div className={styles["learn-more"]}>
                <div className={styles.subheading}>
                  Meet GO-PHOR. Your rapid affordable on-demand courier for
                  business and personal needs!
                </div>
                <ActionAnchorButton
                  href="https://go-phor.com"
                  text="Learn More &rarr;"
                />
              </div>
              <div className={styles["button-section"]}>
                <div className={styles.subheading}>Get Started</div>
                <p className={styles["login-text"]}>
                  Log in to an existing account or sign up for a new account:
                </p>
                <div className={styles["button-item"]}>
                  <ButtonStyleComponent
                    buttonName="Log In"
                    handleButton={login}
                    buttonClass="login-btn-home"
                  />
                  <ButtonStyleComponent
                    buttonName="Sign Up"
                    handleButton={signup}
                    buttonClass="signup-btn-home"
                  />
                </div>
              </div>
            </div>
          </section>

          <div>
            <div className={styles.intro}>
              <p>
                For more information email us at:
                <span>
                  <a href="mailto::info@go-phor.com">info@go-phor.com</a>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Home;
