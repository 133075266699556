import { auth } from "../firebase/config";
import { signOut } from "firebase/auth";

export const useLogout = () => {
  const logout = () => {
    signOut(auth).catch((err) => {
      // handle error message
    });
  };
  return { logout };
};
