import { useState } from "react";
import locationIcon from "../images/location.svg";
import { useSavedLocation } from "../hooks/useSavedLocation";
import styles from "./Locations.module.css";
import EditSavedLocation from "../pages/EditSavedLocation";
import { useNavigate } from "react-router-dom";
import ButtonStyleComponent from "./ButtonStyleComponent";

const SavedLocationItem = ({ location, user }) => {
  const [deleteLocation, setDeleteLocation] = useState(false);
  const [deletePending, setDeletePending] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { deleteSavedLocation } = useSavedLocation();

  const handleDeleteLocation = (e) => {
    setDeleteLocation(true);
  };
  const finishDelete = async () => {
    setDeletePending(true);
    try {
      await deleteSavedLocation(location.id, user);
    } catch (err) {
      setDeletePending(false);
      setDeleteLocation(false);
      setError("Unable to delete saved location. Please try again.");
    }
  };
  const handleCancelDelete = () => {
    setDeleteLocation(false);
  };

  const handleEditLocation = () => {
    navigate("/editsavedlocation", {
      state: {
        user,
        id: location.id,
        locationName: location.locationName,
        locationAddress: location.addressText,
      },
    });
  };

  return (
    <div className={styles["location-section"]} key={location.id}>
      <div className={styles["location-grid"]}>
        <img src={locationIcon} alt="location icon" />
        <div>
          <h1>{location.locationName}</h1>
        </div>
        <div
          className={styles["location-edit"]}
          onClick={handleEditLocation}
        ></div>
        <div
          className={styles["location-delete"]}
          onClick={handleDeleteLocation}
        ></div>
      </div>
      <h3>{location.addressText} </h3>
      {error && (
        <div className="errorMsg">
          <p>{error}</p>
        </div>
      )}
      {deletePending && deleteLocation && !error && (
        <div className="pending">
          <p>Deleting location..</p>
        </div>
      )}
      {deleteLocation && !deletePending && (
        <>
          <div className="errorMsg">
            <p>{`Do you really want to delete the location ${location.locationName}?`}</p>
          </div>

          <div className={styles["button-area"]}>
            <ButtonStyleComponent
              buttonName="Delete"
              handleButton={finishDelete}
            />
            <ButtonStyleComponent
              buttonName="Cancel"
              handleButton={handleCancelDelete}
            />
          </div>
        </>
      )}
    </div>
  );
};
export default SavedLocationItem;
