import styles from "./ButtonStyleComponent.module.css";
import { useRef } from "react";

const ButtonStyleComponent = ({
  buttonName,
  handleButton,
  buttonClass,
  tabIndex,
}) => {
  if (!tabIndex) {
    tabIndex = "0";
  }
  const checkEnter = (e) => {
    if (e.key === "Enter") {
      handleButton(e);
    }
  };
  return (
    <div
      className={`${styles["link-area-styles"]} ${styles[buttonClass]}`}
      tabIndex={tabIndex}
      role="button"
      onKeyUp={checkEnter}
    >
      <div
        className={`${styles["link-styles"]} ${styles[buttonClass]}`}
        onClick={handleButton}
      >
        {buttonName}
      </div>
    </div>
  );
};
export default ButtonStyleComponent;
