import { useReducer, useEffect, useState } from "react";
import { fireDB } from "../firebase/config";
import { getDoc, doc } from "firebase/firestore";

let initialState = {
  document: null,
  pending: false,
  error: null,
  success: null,
};

const firestoreReducer = (state, action) => {
  switch (action.type) {
    case "IS_PENDING":
      return {
        isPending: true,
        document: null,
        success: false,
        error: null,
      };

    case "ERROR":
      return {
        isPending: false,
        document: null,
        success: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const useFirestore = (collection) => {
  const [response, dispatch] = useReducer(firestoreReducer, initialState);
  const [isCancelled, setIsCancelled] = useState(false);

  const dispatchIfNotCancelled = (action) => {
    if (!isCancelled) {
      dispatch(action);
    }
  };
  const getDocument = async (id) => {
    try {
      const docSnapshot = await getDoc(doc(fireDB, collection, id));

      return docSnapshot.exists() ? docSnapshot.data() : null;
    } catch (err) {
      // handle error message //
    }
  };

  useEffect(() => {
    return () => {
      setIsCancelled(true);
    };
  }, []);
  return { response, getDocument };
};
