import { createContext, useReducer, useEffect } from "react";
import { auth } from "../firebase/config";
import { onAuthStateChanged } from "firebase/auth";
import { useFirestore } from "../hooks/useFirestore";

export const AuthContext = createContext();

export const authReducer = (state, action) => {
  switch (action.type) {
    case "AUTH_IS_READY":
      return { user: action.payload, authIsReady: true };
    case "LOGIN":
      return { user: action.payload, authIsReady: true };
    case "UPDATE_PROFILE":
      return {
        user: {
          ...action.payload.user,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          phoneNumber: action.payload.phoneNumber,
          email: action.payload.email,
        },
        authIsReady: true,
      };
    case "UPDATE_IMAGE":
      return {
        user: {
          ...state.user,
          imageURL: action.payload.imageURL,
        },
        authIsReady: true,
      };
    case "SET_USERNAME":
      return {
        user: {
          ...action.payload.user,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
        },
        authIsReady: true,
      };
    default:
      return state;
  }
};

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    authIsReady: false,
  });

  const { getDocument } = useFirestore("users");

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, async (user) => {
      let firstName = null;
      let lastName = null;
      let email = null;
      let phoneNumber = null;
      let createdAt = null;
      let imageURL = null;
      try {
        if (user) {
          const data = await getDocument(user.uid);

          if (data) {
            firstName = data.firstName;
            lastName = data.lastName;
            email = data.email;
            phoneNumber = data.phoneNumber;
            createdAt = data.createdAt.toDate().toString();
            imageURL = data.imageURL;
          }
        }
      } catch (err) {
        //handle error
      }
      dispatch({
        type: "AUTH_IS_READY",
        payload: user
          ? {
              ...user,
              firstName,
              lastName,
              email,
              phoneNumber,
              createdAt,
              imageURL,
            }
          : null,
      });
      // unsub();
    });
    return unsub;
  }, []);

  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
