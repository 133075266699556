import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import { useAuthContext } from "./hooks/useAuthContext";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Help from "./pages/Help";
import Dashboard from "./pages/Dashboard";
import Signup2 from "./pages/Signup2";
import Profile from "./pages/Profile";
import EditProfile from "./pages/EditProfile";
import PaymentMethod from "./pages/PaymentMethod";
import SavedLocations from "./pages/SavedLocations";
import EditSavedLocation from "./pages/EditSavedLocation";
import DeliveriesInProgress from "./pages/DeliveriesInProgress";
import DeliveriesNeedPayment from "./pages/DeliveriesNeedPayment";
import DeliveryHistory from "./pages/DeliveryHistory";
import NavbarComponent from "./components/NavbarComponent";
import AddPaymentMethod from "./pages/AddPaymentMethod";
import ResponseAddPaymentMethod from "./pages/ResponseAddPaymentMethod";
import AddSavedLocation from "./pages/AddSavedLocation";
import PageNotFound from "./pages/PageNotFound";

// route guarding rules:
//  - if user and goes to someplace you shouldn't, then go to dashboard
//  - if not user and goes to someplace that you shouldn't then go to login

function App() {
  const { authIsReady, user } = useAuthContext();

  return (
    <>
      {authIsReady && (
        <BrowserRouter>
          <NavbarComponent />
          <Routes>
            <Route
              exact
              path="/"
              element={user ? <Navigate to="/dashboard" /> : <Home />}
            />
            <Route
              path="/login"
              element={user ? <Navigate to="/dashboard" /> : <Login />}
            />
            <Route
              path="/signup"
              element={user ? <Navigate to="/dashboard" /> : <Signup />}
            />
            <Route path="/help" element={<Help />} />

            <Route
              path="/dashboard"
              element={!user ? <Navigate to="/login" /> : <Dashboard />}
            />
            <Route path="/signup2" element={<Signup2 />} />

            <Route
              path="/savedlocations"
              element={!user ? <Navigate to="/login" /> : <SavedLocations />}
            />
            <Route
              path="/editsavedlocation"
              element={!user ? <Navigate to="/login" /> : <EditSavedLocation />}
            />
            <Route
              path="/addsavedlocation"
              element={!user ? <Navigate to="/login" /> : <AddSavedLocation />}
            />
            <Route
              path="/profile"
              element={!user ? <Navigate to="/login" /> : <Profile />}
            />
            <Route
              path="/paymentmethod"
              element={!user ? <Navigate to="/login" /> : <PaymentMethod />}
            />
            <Route
              path="/editprofile"
              element={!user ? <Navigate to="/login" /> : <EditProfile />}
            />
            <Route
              path="/deliveriesprogress"
              element={
                !user ? <Navigate to="/login" /> : <DeliveriesInProgress />
              }
            />
            <Route
              path="/deliveriesneedpay"
              element={
                !user ? <Navigate to="/login" /> : <DeliveriesNeedPayment />
              }
            />
            <Route
              path="/deliveryhistory"
              element={!user ? <Navigate to="/login" /> : <DeliveryHistory />}
            />
            <Route
              path="/addpaymentmethod"
              element={!user ? <Navigate to="/login" /> : <AddPaymentMethod />}
            />
            <Route
              path="/responsepaymentmethod"
              element={
                !user ? <Navigate to="/login" /> : <ResponseAddPaymentMethod />
              }
            />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </BrowserRouter>
      )}
    </>
  );
}

export default App;
