import HeadingTitle from "../components/HeadingTitle";
import ModalWindow from "../components/ModalWindow";
import { useQueryDeliveryCollection } from "../hooks/useQueryDeliveryCollection";
import { useAuthContext } from "../hooks/useAuthContext";
import { useNavigate } from "react-router-dom";
import styles from "./Deliveries.module.css";
import ButtonStyleComponent from "../components/ButtonStyleComponent";
import Footer from "../components/Footer";

const Dashboard = () => {
  const { user } = useAuthContext();
  const sevenDays = 1 * 7 * 24 * 60 * 60 * 1000;
  const curDate = new Date();
  const weekPrior = new Date(curDate.getTime() - sevenDays);
  const navigate = useNavigate();
  const deliveryProgress = () => {
    navigate("/deliveriesprogress");
  };

  const deliveryPay = () => {
    navigate("/deliveriesneedpay");
  };

  const deliveryHistory = () => {
    navigate("/deliveryhistory");
  };

  const {
    documents: jobs,
    error,
    count,
  } = useQueryDeliveryCollection(
    "jobs",
    ["requesterUID", "==", user.uid],
    [
      "jobStatusCode",
      "in",
      ["PENDING", "ACCEPTED", "PICKEDUP", "PARTIAL_DELIVERY"],
    ],
    [],
    ["createdAt", "desc"]
  );

  const {
    documents: jobsPay,
    error: errorPay,
    count: countPay,
  } = useQueryDeliveryCollection(
    "jobs",
    ["requesterUID", "==", user.uid],
    ["jobStatusCode", "in", ["DELIVERY_REPORTED", "DELIVERED"]],
    [],
    ["createdAt", "desc"]
  );
  const {
    documents: jobsWeek,
    error: errorWeek,
    count: countWeek,
  } = useQueryDeliveryCollection(
    "jobs",
    ["requesterUID", "==", user.uid],
    ["jobStatusCode", "in", ["COMPLETE"]],
    ["createdAt", ">=", weekPrior],
    ["createdAt", "desc"]
  );

  return (
    <>
      <div className="section">
        <HeadingTitle title="Dashboard" />

        <ModalWindow>
          <div className={styles["delivery-card"]}>
            {count === 1 && <h1>{count} delivery in progress</h1>}
            {count !== 1 && <h1>{count} deliveries in progress</h1>}

            <ButtonStyleComponent
              buttonName="Show Deliveries"
              handleButton={deliveryProgress}
              buttonClass="delivery"
            />
          </div>
          <div className={styles["delivery-card"]}>
            {countPay === 1 && <h1>{countPay} delivery needs payment</h1>}
            {countPay !== 1 && <h1>{countPay} deliveries need payment</h1>}
            <ButtonStyleComponent
              buttonName="Show Deliveries"
              handleButton={deliveryPay}
              buttonClass="delivery"
            />
          </div>
          <div className={styles["delivery-card"]}>
            {countWeek === 1 && <h1>{countWeek} delivery in the last week</h1>}
            {countWeek !== 1 && (
              <h1>{countWeek} deliveries in the last week</h1>
            )}
            <ButtonStyleComponent
              buttonName="Show Deliveries"
              handleButton={deliveryHistory}
              buttonClass="delivery"
            />
          </div>
        </ModalWindow>
        <Footer />
      </div>
    </>
  );
};

export default Dashboard;
