import { useQueryDeliveryCollection } from "../hooks/useQueryDeliveryCollection";
import { useAuthContext } from "../hooks/useAuthContext";
import DeliveryList from "../components/DeliveryList";
import HeadingTitle from "../components/HeadingTitle";
import ModalWindow from "../components/ModalWindow";
import Footer from "../components/Footer";
import styles from "./Deliveries.module.css";
const DeliveriesNeedPayment = () => {
  const { user } = useAuthContext();
  const {
    documents: jobs,
    error,
    count,
  } = useQueryDeliveryCollection(
    "jobs",
    ["requesterUID", "==", user.uid],
    ["jobStatusCode", "in", ["DELIVERY_REPORTED", "DELIVERED"]],
    [],
    ["createdAt", "desc"]
  );

  return (
    <>
      <div className="section">
        <HeadingTitle title="Deliveries Pending Payment" />

        <div className="section">
          <ModalWindow>
            {(!jobs || jobs.length === 0) && (
              <div className={styles["delivery-card"]}>
                <h1>No deliveries are pending payment.</h1>
              </div>
            )}

            {jobs && jobs.map((job) => <DeliveryList job={job} key={job.id} />)}
          </ModalWindow>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default DeliveriesNeedPayment;
