import { useState, useEffect } from "react";
import { fireDB } from "../firebase/config";
import { collection, onSnapshot, query, orderBy } from "firebase/firestore";
export const useLocationSubCollection = (c, id, subCollection) => {
  const [documents, setDocuments] = useState(null);

  useEffect(() => {
    let ref = collection(fireDB, c, id, subCollection);
    const q = query(ref, orderBy("locationName", "asc"));
    const unsub = onSnapshot(q, (snapshot) => {
      let results = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setDocuments(results);
    });
    return () => unsub();
  }, [c]);
  return { documents };
};
