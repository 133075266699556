// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAqpUsccJYwnx2Ayp23tkVnI7Zyma4gz0E",
  authDomain: "go-phor-development.firebaseapp.com",
  databaseURL: "https://go-phor-development.firebaseio.com",
  projectId: "go-phor-development",
  storageBucket: "go-phor-development.appspot.com",
  messagingSenderId: "765334514657",
  appId: "1:765334514657:web:ff91e6a8f0531026a700a0",
  measurementId: "G-7PV2F584KT",
};

const stripePublishableKey = "pk_test_RnPMrzns53WzxkJd6EMBtpmw";

export { firebaseConfig, stripePublishableKey };
