import { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { useStripePaymentMethods } from "../hooks/useStripePaymentMethods";
import styles from "./AddPaymentMethod.module.css";
import HeadingTitle from "../components/HeadingTitle";
import AddStripePaymentMethod from "../components/AddStripePaymentMethod";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";
import { stripePublishableKey } from "../env/env-config";
import Footer from "../components/Footer";

const AddPaymentMethod = () => {
  const navigate = useNavigate();
  const [pending, setPending] = useState(true);
  const [error, setError] = useState("");
  const { getKey } = useStripePaymentMethods();
  const [clientSecret, setClientSecret] = useState("");

  const handleContinue = () => {
    navigate("/paymentmethod");
  };

  // Make sure to call `loadStripe` outside of a component’s render to avoid
  // recreating the `Stripe` object on every render.
  const stripePromise = loadStripe(stripePublishableKey);
  const stripeOptions = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
  };

  const appearance = {
    theme: "stripe",
    variables: {
      colorPrimary: "#5d8abb",
      colorBackground: "#ffffff",
      colorText: "#30313d",
      colorDanger: "#ff5349",
      spacingUnit: "6px",
      borderRadius: "8px",
    },
  };

  useEffect(() => {
    const getClientSecret = async () => {
      try {
        setClientSecret(await getKey());
        setPending(false);
        setError("");
      } catch (err) {
        setPending(false);
        setError("Unable to initialize. Please try again.");
      }
    };
    getClientSecret();
  }, []);

  return (
    <>
      <div className="section">
        <HeadingTitle title="Add New Payment Method" />
        {error && (
          <div className="errorMsg">
            <p>{error}</p>
            <div className={styles["link-area-styles"]}>
              <div className={styles["link-styles"]} onClick={handleContinue}>
                Return to Payment Methods
              </div>
            </div>
          </div>
        )}
        {pending && (
          <div className="pending">
            <p>Please wait...</p>
          </div>
        )}
        {clientSecret && !pending && !error && (
          <Elements
            stripe={stripePromise}
            options={{ clientSecret, appearance }}
          >
            <AddStripePaymentMethod />
          </Elements>
        )}

        <Footer />
      </div>
    </>
  );
};
export default AddPaymentMethod;
