import { useState } from "react";

// firebase imports
import { auth } from "../firebase/config";
import { createUserWithEmailAndPassword } from "firebase/auth";

export const useSignup = () => {
  const signup = async (email, password) => {
    const res = await createUserWithEmailAndPassword(auth, email, password);
  };

  return { signup };
};
