import styles from "./Logobar.module.css";
const Logobar = () => {
  return (
    <div>
      <div>
        <svg
          className={styles.logo}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 88 88"
        >
          <path d="M79 4H9a6.007 6.007 0 0 0-6 6v50a6.007 6.007 0 0 0 6 6h25v5a5.006 5.006 0 0 1-5 5 5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h38a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5 5.006 5.006 0 0 1-5-5v-5h25a6.007 6.007 0 0 0 6-6V10a6.007 6.007 0 0 0-6-6zM9 6h70a4.004 4.004 0 0 1 4 4v46H5V10a4.004 4.004 0 0 1 4-4zm53 75v1H26v-1a3.003 3.003 0 0 1 3-3h30a3.003 3.003 0 0 1 3 3zm-7.894-5H33.894A6.979 6.979 0 0 0 36 71v-5h16v5a6.979 6.979 0 0 0 2.105 5zM83 60a4.004 4.004 0 0 1-4 4H9a4.004 4.004 0 0 1-4-4v-2h78z" />
          <path d="M9 53h70a1 1 0 0 0 1-1V10a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1v42a1 1 0 0 0 1 1zm1-42h68v40H10z" />
          <path d="M60 16H28a1 1 0 0 0-1 1v28a1 1 0 0 0 1 1h32a1 1 0 0 0 1-1V17a1 1 0 0 0-1-1zm-1 8H49v-6h10zm-12-6v9l-2.4-1.8a.998.998 0 0 0-1.2 0L41 27v-9zm-8 0v6H29v-6zM29 44V26h10v3a1 1 0 0 0 1.6.8l3.4-2.55 3.4 2.55A1 1 0 0 0 49 29v-3h10v18zM42 62h4a1 1 0 0 0 0-2h-4a1 1 0 0 0 0 2z" />
          <path d="M42 37H32a1 1 0 0 0 0 2h10a1 1 0 0 0 0-2zM37 40h-5a1 1 0 0 0 0 2h5a1 1 0 0 0 0-2zM69.707 27.293a1 1 0 0 0-1.414 1.414L70.586 31l-2.293 2.293a1 1 0 0 0 1.414 1.414l3-3a1 1 0 0 0 0-1.414zM19.707 27.293a1 1 0 0 0-1.414 0l-3 3a1 1 0 0 0 0 1.414l3 3a1 1 0 0 0 1.414-1.414L17.414 31l2.293-2.293a1 1 0 0 0 0-1.414z" />
        </svg>

        <svg
          className={styles.logo}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 88 88"
        >
          <g data-name="Truck Delivery">
            <path d="M73 64a3 3 0 1 0 3 3 3.003 3.003 0 0 0-3-3zm0 4a1 1 0 1 1 1-1 1 1 0 0 1-1 1zM30 64a3 3 0 1 0 3 3 3.003 3.003 0 0 0-3-3zm0 4a1 1 0 1 1 1-1 1 1 0 0 1-1 1zM62 61a1 1 0 0 0 1-1V34h10.508L83 46.34V60a1 1 0 0 0 2 0V46a1.003 1.003 0 0 0-.207-.61l-10-13A1.001 1.001 0 0 0 74 32H62a1 1 0 0 0-1 1v27a1 1 0 0 0 1 1z" />
            <path d="M79.848 46.441a1 1 0 0 0-.105-1.05l-6.92-9A1 1 0 0 0 72.03 36H66a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h12.95a1.002 1.002 0 0 0 .898-.559zM67 45v-7h4.538l5.382 7zM54 27h3v33a1 1 0 0 0 2 0V26a1 1 0 0 0-1-1h-4a1 1 0 0 0 0 2zM20 61a1 1 0 0 0 1-1V27h3a1 1 0 0 0 0-2h-4a1 1 0 0 0-1 1v34a1 1 0 0 0 1 1z" />
            <path d="M87 63h-8.264a6.984 6.984 0 0 0-11.472 0H35.736a6.984 6.984 0 0 0-11.472 0H16a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h7.295a6.996 6.996 0 0 0 13.41 0h29.59a6.996 6.996 0 0 0 13.41 0H87a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1zm-70 4v-2h6.291A7.747 7.747 0 0 0 23 67zm13 5a5 5 0 1 1 5-5 5.006 5.006 0 0 1-5 5zm7-5a7.785 7.785 0 0 0-.291-2h29.582A7.81 7.81 0 0 0 66 67zm36 5a5 5 0 1 1 5-5 5.006 5.006 0 0 1-5 5zm13-5h-6a7.739 7.739 0 0 0-.291-2H86zM17 39a1 1 0 0 0-1-1H1a1 1 0 0 0 0 2h15a1 1 0 0 0 1-1zM17 45a1 1 0 0 0-1-1H5a1 1 0 0 0 0 2h11a1 1 0 0 0 1-1zM17 51a1 1 0 0 0-1-1h-6a1 1 0 0 0 0 2h6a1 1 0 0 0 1-1zM39 38a12 12 0 1 0-12-12 12.013 12.013 0 0 0 12 12zm0-22a10 10 0 1 1-10 10 10.012 10.012 0 0 1 10-10z" />
            <path d="M41.293 29.707a1 1 0 0 0 1.414-1.414L40 25.586V22a1 1 0 0 0-2 0v4a1 1 0 0 0 .293.707zM66 49a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2z" />
          </g>
        </svg>

        <svg
          className={styles.logo}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 88 88"
        >
          <g data-name="Logistic pin">
            <path d="M54 61a1 1 0 0 0-1 1v9H3V35h17v7a1 1 0 0 0 1.581.814L28 38.229l6.419 4.585A1 1 0 0 0 36 42v-7h6a1 1 0 0 0 0-2h-6V23h11a1 1 0 0 0 0-2H2a1 1 0 0 0-1 1v50a1 1 0 0 0 1 1h52a1 1 0 0 0 1-1V62a1 1 0 0 0-1-1zM34 40.057l-5.419-3.871a1 1 0 0 0-1.162 0L22 40.056V23h12zM3 23h17v10H3z" />
            <path d="M66 15a21.024 21.024 0 0 0-21 21c0 11.268 19.395 35.595 20.22 36.626a1 1 0 0 0 1.56 0C67.607 71.596 87 47.267 87 36a21.024 21.024 0 0 0-21-21zm0 55.38C62.331 65.657 47 45.355 47 36a19 19 0 0 1 38 0c0 9.352-15.331 29.656-19 34.38z" />
            <path d="M66 27a9 9 0 1 0 9 9 9.01 9.01 0 0 0-9-9zm0 16a7 7 0 1 1 7-7 7.008 7.008 0 0 1-7 7zM25 64a1 1 0 0 0-1-1H6a1 1 0 0 0 0 2h18a1 1 0 0 0 1-1zM6 67a1 1 0 0 0 0 2h8a1 1 0 0 0 0-2z" />
          </g>
        </svg>

        <svg
          className={styles.logo}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 88 88"
        >
          <path d="M85.232 40.768a5.954 5.954 0 0 0-8.414 0l-.818.818V10a1 1 0 0 0-1-1H25a1 1 0 0 0-1 1v35.017a21.945 21.945 0 0 0-4.33 1.877l-.67.383V44a1 1 0 0 0-1-1h-8a1 1 0 0 0-1 1v2H2a1 1 0 0 0 0 2h7v26H2a1 1 0 0 0 0 2h7v2a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-4h41a1 1 0 0 0 .707-.293l24.525-24.525a5.957 5.957 0 0 0 0-8.414zM74 19H57v-8h17zm-19 4.865-4.36-3.634a1.002 1.002 0 0 0-1.28 0L45 23.865V11h10zM26 11h17v8H26zm0 10h17v5a1 1 0 0 0 1.64.769L50 22.302l5.36 4.467A1 1 0 0 0 57 26v-5h17v22.586l-9.14 9.14A6.008 6.008 0 0 0 59 48H43.283l-1.206-.742A21.848 21.848 0 0 0 26 44.49zm-9 56h-6V45h6zm66.818-29.232L59.586 72H19V49.58l1.663-.95a19.962 19.962 0 0 1 20.365.331l1.448.89A1.005 1.005 0 0 0 43 50h16a4 4 0 0 1 0 8H40a1 1 0 0 0 0 2h19a6.007 6.007 0 0 0 5.764-4.35l13.468-13.468a3.95 3.95 0 0 1 5.586 5.586z" />
        </svg>
      </div>
    </div>
  );
};
export default Logobar;
