import styles from "./Footer.module.css";

const Footer = () => {
  const dateYear = new Date().getFullYear();

  return (
    <div>
      {dateYear == "2023" && (
        <div className={styles.copyright}>
          <p>&#169;2023 GO-PHOR LLC</p>
        </div>
      )}
      {dateYear != "2023" && (
        <div className={styles.copyright}>
          <p>&#169;2023 - {dateYear} GO-PHOR LLC</p>
        </div>
      )}
    </div>
  );
};
export default Footer;
