import { useState } from "react";
import { format } from "date-fns";
import { useAuthContext } from "../hooks/useAuthContext";
import PersonIcon from "../images/personIcon.svg";
import ModalWindow from "../components/ModalWindow";
import HeadingTitle from "../components/HeadingTitle";
import styles from "./EditProfile.module.css";
import { useNavigate } from "react-router-dom";
import { useEditProfile } from "../hooks/useEditProfile";
import { fireStorage, fireDB } from "../firebase/config";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { setDoc, doc } from "firebase/firestore";
import ButtonStyleComponent from "../components/ButtonStyleComponent";
import Footer from "../components/Footer";

/////////////////////////////////////////////////////////////////////
//                                                                 //
// EditProfile                                                     //
//                                                                 //
/////////////////////////////////////////////////////////////////////
const EditProfile = () => {
  const { user, dispatch } = useAuthContext();
  const [change, setChange] = useState(false);
  const [pending, setPending] = useState(false);
  const [error, setError] = useState(null);
  const [imagePending, setImagePending] = useState(false);
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [phoneNumber, setPhoneNumber] = useState(
    user.phoneNumber ? user.phoneNumber : ""
  );
  const [imageError, setImageError] = useState(null);
  const [email, setEmail] = useState(user.email);
  const navigate = useNavigate();
  const { updateProfileInFirestore, updateEmail, updatePhone } =
    useEditProfile();
  const [updateImage, setUpdateImage] = useState(false);

  /////////////////////////////////////////////////////////////////////
  //                                                                 //
  // handleUpdateProfileSubmit                                       //
  //                                                                 //
  /////////////////////////////////////////////////////////////////////
  const handleUpdateProfileSubmit = async (e) => {
    setPending(false);
    setError(null);

    if (e) {
      e.preventDefault();
    }
    try {
      if (!phoneNumber && !user.phoneNumber) {
        user.phoneNumber = "";
      }

      // check to see if anything has changed
      if (
        !(
          user.firstName === firstName &&
          user.lastName === lastName &&
          user.email === email &&
          user.phoneNumber === phoneNumber
        )
      ) {
        setPending(true);

        // if email has changed
        if (user.email !== email) {
          // call update email function
          await updateEmail(email);
        }
        // if phone number has changed
        if (user.phoneNumber !== phoneNumber) {
          // call update phone number function
          await updatePhone(phoneNumber);
        }

        await updateProfileInFirestore(
          user.uid,
          firstName,
          lastName,
          phoneNumber,
          email
        );
        dispatch({
          type: "UPDATE_PROFILE",
          payload: { user, firstName, lastName, phoneNumber, email },
        });
        setPending(false);
        navigate("/profile");
      }
    } catch (err) {
      setPending(false);
      setError(
        "Unable to save profile changes. Please try again in a few minutes."
      );
    }
  };

  /////////////////////////////////////////////////////////////////////
  //                                                                 //
  // handleFileChange                                                //
  //                                                                 //
  // this is called when the user selects a file from their device   //
  // for a user profile picture;                                     //
  //                                                                 //
  /////////////////////////////////////////////////////////////////////
  const handleFileChange = (e) => {
    setImageError(null);
    setImagePending(false);
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    let selected = e.target.files[0];
    if (!selected.type.includes("image")) {
      setImageError("Selected file must be an image.");
      return;
    }

    if (selected.size > 600000) {
      setImageError("Selected file must be less than 600KB.");
      return;
    }
    setImageError(null);
    setImagePending(true);

    const fileIndex = selected.name.lastIndexOf(".");
    const fileType = selected.name.substring(fileIndex);
    const fileName = new Date().getTime() + fileType;
    const uploadPath = `user/${user.uid}/public/${fileName}`;
    const storageRef = ref(fireStorage, uploadPath);
    const uploadTask = uploadBytesResumable(storageRef, file, "data_url");
    uploadTask.on(
      "state_changed",
      null,
      (error) => {
        setImageError(
          "Unable to save profile image. Please try again in a few minutes."
        );
        setImagePending(false);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (URL) => {
          try {
            await setDoc(
              doc(fireDB, "users", user.uid),
              { imageURL: URL, updatedAt: new Date() },
              { merge: true }
            );
            dispatch({
              type: "UPDATE_IMAGE",
              payload: { imageURL: URL },
            });
            setUpdateImage(false);
          } catch (err) {
            setImageError(
              "Unable to save profile image. Please try again in a few minutes."
            );
          }
          setImagePending(false);
        });
      }
    );
  };

  /////////////////////////////////////////////////////////////////////
  //                                                                 //
  // handleUpdatePhotoClick                                          //
  //                                                                 //
  // sets the updateImage state variable to true; when the screen is //
  // re-rendered it shows the area to select a file hides the button //
  // to upload and save a profile photo                              //
  //                                                                 //
  /////////////////////////////////////////////////////////////////////
  const handleUpdatePhotoClick = () => {
    setUpdateImage(true);
  };

  /////////////////////////////////////////////////////////////////////
  //                                                                 //
  // handleCancel                                                    //
  //                                                                 //
  /////////////////////////////////////////////////////////////////////
  const handleCancel = (e) => {
    navigate("/profile");
  };

  return (
    <>
      <div className={styles["update-section"]}>
        <HeadingTitle title="Edit Profile Information" />
        <ModalWindow>
          <form className={styles["update-form"]}>
            {!user.imageURL && (
              <>
                <img src={PersonIcon} className={styles.photo}></img>
                {!updateImage && (
                  <ButtonStyleComponent
                    handleButton={handleUpdatePhotoClick}
                    buttonName="Upload and Save Photo"
                    buttonClass="change-photo"
                  />
                )}
                {imagePending && (
                  <div className="pending">
                    <p>Updating image...</p>
                  </div>
                )}
                {updateImage && (
                  <label className={styles["update-form"]}>
                    <span>Profile image:</span>
                    <input type="file" onChange={handleFileChange} />
                    {imageError && (
                      <div className="errorMsg">
                        <p>{imageError}</p>
                      </div>
                    )}
                  </label>
                )}
              </>
            )}
            {user.imageURL && (
              <div>
                <img
                  className={styles["photo-img"]}
                  src={user.imageURL}
                  alt="user photo"
                />
                {!updateImage && (
                  <ButtonStyleComponent
                    handleButton={handleUpdatePhotoClick}
                    buttonName="Upload and Save Photo"
                    buttonClass="change-photo"
                  />
                )}
                {imagePending && (
                  <div className="pending">
                    <p>Updating image...</p>
                  </div>
                )}
                {updateImage && (
                  <label>
                    <span>Profile image:</span>
                    <input type="file" onChange={handleFileChange} />
                    {imageError && (
                      <div className="errorMsg">
                        <p>{imageError}</p>
                      </div>
                    )}
                  </label>
                )}
              </div>
            )}

            <label className={styles["update-form"]}>
              <span>First Name:</span>
              <input
                type="text"
                onChange={(e) => {
                  setFirstName(e.target.value);
                  setChange(true);
                }}
                value={firstName}
              />
            </label>
            <label>
              <span>Last Name:</span>
              <input
                type="text"
                onChange={(e) => {
                  setLastName(e.target.value);
                  setChange(true);
                }}
                value={lastName}
              />
            </label>
            <label>
              <span>Email:</span>
              <input
                type="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                  setChange(true);
                }}
                value={email}
              />
            </label>
            <label>
              <span>Phone number:</span>
              <input
                type="text"
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                  setChange(true);
                }}
                value={phoneNumber}
              />
            </label>
            {error && (
              <div className="errorMsg">
                <p>{error}</p>
              </div>
            )}
            <div className={styles["button-section"]}>
              {!pending && change && (
                <ButtonStyleComponent
                  buttonName="Update Profile"
                  handleButton={handleUpdateProfileSubmit}
                  buttonClass="edit-profile-btn"
                />
              )}
              {!pending && (
                <ButtonStyleComponent
                  buttonName="Cancel"
                  handleButton={handleCancel}
                  buttonClass="cancel-btn"
                />
              )}
              {pending && (
                <div className="pending">
                  <p>Updating your profile...</p>
                </div>
              )}
            </div>
          </form>
        </ModalWindow>
      </div>
      <Footer />
    </>
  );
};
export default EditProfile;
