import ModalWindow from "../components/ModalWindow";
import HeadingTitle from "../components/HeadingTitle";
import ResponseComponent from "../components/ResponseComponent";
import { Elements } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useStripePaymentMethods } from "../hooks/useStripePaymentMethods";
import styles from "./ResponseAddPaymentMethod.module.css";
import { stripePublishableKey } from "../env/env-config";
import Footer from "../components/Footer";

const ResponseAddPaymentMethod = () => {
  const [pending, setPending] = useState(false);
  const [error, setError] = useState("");
  const { getKey } = useStripePaymentMethods();
  const [clientSecret, setClientSecret] = useState("");

  // Make sure to call `loadStripe` outside of a component’s render to avoid
  // recreating the `Stripe` object on every render.
  const stripePromise = loadStripe(stripePublishableKey);

  const stripeOptions = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
  };

  const appearance = {
    theme: "stripe",
    variables: {
      colorPrimary: "#5d8abb",
      colorBackground: "#ffffff",
      colorText: "#30313d",
      colorDanger: "#ff5349",
      fontFamily: "Ideal Sans, system-ui, sans-serif",
      spacingUnit: "8px",
      borderRadius: "8px",
    },
  };

  useEffect(() => {
    const getClientSecret = async () => {
      try {
        setClientSecret(await getKey());
      } catch (err) {
        setError("Unable to initialize. Please try again.");
      }
    };
    getClientSecret();
  }, []);

  return (
    <>
      <div className="section">
        <HeadingTitle title="Add New Payment Method Response" />
        <ModalWindow>
          {clientSecret && (
            <Elements
              stripe={stripePromise}
              options={{ clientSecret, appearance }}
            >
              <ResponseComponent />
            </Elements>
          )}
        </ModalWindow>
        <Footer />
      </div>
    </>
  );
};
export default ResponseAddPaymentMethod;
