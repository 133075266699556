import { useState, useEffect, useRef } from "react";
import { fireDB } from "../firebase/config";
import {
  collection,
  onSnapshot,
  query,
  orderBy,
  where,
} from "firebase/firestore";

export const useQueryDeliveryCollection = (c, _q, _q2, _q3, _o) => {
  const [documents, setDocuments] = useState(null);
  const [error, setError] = useState(null);
  const [count, setCount] = useState(0);
  const q = useRef(_q).current;
  const q2 = useRef(_q2).current;
  const q3 = useRef(_q3).current;
  const o = useRef(_o).current;

  useEffect(() => {
    setCount(0);
    let ref = collection(fireDB, c);

    if (q.length > 0 && q2.length > 0 && q3.length > 0) {
      ref = query(ref, where(...q), where(...q2), where(...q3));
    } else if (q.length > 0 && q2.length > 0) {
      ref = query(ref, where(...q), where(...q2));
    } else if (q.length > 0) {
      ref = query(ref, where(...q));
    } else {
    }

    if (o) {
      ref = query(ref, orderBy(...o));
    }
    const unsub = onSnapshot(
      ref,
      (snapshot) => {
        let results = [];
        snapshot.docs.forEach((doc) => {
          const docData = doc.data();

          if (docData && docData.deliveryLocations) {
            for (const l of docData.deliveryLocations) {
              if (
                l.deliveryReportedDate &&
                typeof l.deliveryReportedDate === "string"
              ) {
                l.deliveryReportedDate = new Date(l.deliveryReportedDate);
              } else if (l.deliveryReportedDate) {
                l.deliveryReportedDate = l.deliveryReportedDate.toDate();
              }
            }
          }
          results.push({ ...docData, id: doc.id });
        });

        setDocuments(results);
        setError(null);
        setCount(results.length);
      },
      (error) => {
        setError("Could not fetch the data");
      }
    );

    return () => unsub();
  }, [c, q, o]);
  return { documents, error, count };
};
