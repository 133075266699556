import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useState } from "react";
import ModalWindow from "./ModalWindow";
import styles from "./AddStripePaymentMethod.module.css";
import { useNavigate } from "react-router-dom";
import ButtonStyleComponent from "./ButtonStyleComponent";

const AddStripePaymentMethod = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState("");
  const [pending, setPending] = useState(false);
  const navigate = useNavigate();
  const domainProtocolRoot = window.location.href.substring(
    0,
    window.location.href.indexOf(window.location.pathname)
  );

  const handleCancelBtn = () => {
    setError("");
    setPending(false);
    navigate("/paymentmethod");
  };

  const handleAddPaymentMethod = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setPending(true);
    setError("");
    const { error } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${domainProtocolRoot}/responsepaymentmethod`,
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setError(
        "Unable to add new payment method at this time. Please try again."
      );
      setPending(false);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <>
      <ModalWindow>
        <form className={styles["stripe-payment-form"]}>
          <PaymentElement />
          {pending && !error && (
            <div className="pending">
              <p>Adding new payment method. Please wait.</p>
            </div>
          )}
          {error && (
            <div className="errorMsg">
              <p>{error}</p>
            </div>
          )}
          {!pending && (
            <div className={styles["btn-area"]}>
              <ButtonStyleComponent
                buttonName="Submit"
                handleButton={handleAddPaymentMethod}
              />
              <ButtonStyleComponent
                buttonName="Cancel"
                handleButton={handleCancelBtn}
              />
            </div>
          )}
        </form>
      </ModalWindow>
    </>
  );
};
export default AddStripePaymentMethod;
