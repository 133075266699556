import { useState } from "react";
import { useCompleteSignup } from "../hooks/useCompleteSignup";
import { useAuthContext } from "../hooks/useAuthContext";
import { useNavigate } from "react-router-dom";
import HeadingTitle from "../components/HeadingTitle";
import ModalWindow from "../components/ModalWindow";
import Footer from "../components/Footer";
import styles from "./Signup2.module.css";
import ButtonStyleComponent from "../components/ButtonStyleComponent";

const Signup2 = () => {
  const navigate = useNavigate();
  const [pending, setPending] = useState(false);
  const [error, setError] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [agree, setAgree] = useState(false);
  const { user, dispatch } = useAuthContext();
  const { completeSignup } = useCompleteSignup();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPending(true);
    try {
      await completeSignup(user.uid, firstName, lastName);
      dispatch({
        type: "SET_USERNAME",
        payload: { user, firstName, lastName },
      });
      navigate("/dashboard");
    } catch (err) {
      setPending(false);
      setError("Unable to complete the signup at this time. Please try again.");
    }
  };

  const handleCheckbox = (e) => {
    setAgree(e.target.checked);
  };

  return (
    <>
      <div className="section">
        <HeadingTitle title="Complete Your GO-PHOR Profile" />
        <ModalWindow>
          <form className={styles["signup-form"]}>
            <div>
              <label className={styles["signup-form"]}>
                <span>First name: </span>
                <input
                  required
                  type="text"
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstName}
                  placeholder="First Name"
                />
              </label>
            </div>
            <div>
              <label className={styles["signup-form"]}>
                <span>Last Name: </span>

                <input
                  required
                  type="text"
                  onChange={(e) => setLastName(e.target.value)}
                  value={lastName}
                  placeholder="Last Name"
                />
              </label>
            </div>

            <div className={styles.checkbox}>
              <input
                className={styles["checkbox-input"]}
                type="checkbox"
                onClick={handleCheckbox}
                value={agree}
              />
              <div className={styles["checkbox-text"]}>
                <p>I have read, understood, and hereby agree to the GO-PHOR </p>
                <a
                  className={styles["checkbox-text"]}
                  href="https://www.go-phor.com/t-s-and-c-s"
                  target="_blank"
                >
                  Terms & Conditions
                </a>
                <p> and the </p>
                <a
                  className={styles["checkbox-text"]}
                  href="https://www.go-phor.com/privacypolicy"
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </div>
            </div>
            {pending && (
              <div className="pending">
                <p>Updating account...</p>
              </div>
            )}
            {error && (
              <div className="errorMsg">
                <p>{error}</p>
              </div>
            )}
            <div className={styles["button-section"]}>
              <div className={styles["button-item"]}>
                {agree && firstName && lastName && !pending && (
                  <ButtonStyleComponent
                    buttonName="Continue"
                    buttonClass="btn-signup2"
                    handleButton={handleSubmit}
                  />
                )}

                {!(agree && firstName && lastName) && !pending && (
                  <ButtonStyleComponent
                    buttonName="Continue"
                    buttonClass="btn-disabled-signup2"
                    tabIndex="-1"
                  />
                )}
              </div>
            </div>
          </form>
        </ModalWindow>
        <Footer />
      </div>
    </>
  );
};
export default Signup2;
