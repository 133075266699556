import { fireDB, cloudFuncs } from "../firebase/config";
import { httpsCallable } from "firebase/functions";
import { doc, setDoc } from "firebase/firestore";

export const useCompleteSignup = () => {
  const completeSignup = async (userId, fname, lname) => {
    const curDateTime = new Date();
    const userDoc = doc(fireDB, "users", userId);
    await setDoc(
      userDoc,
      {
        firstName: fname,
        lastName: lname,
        agreedToTermsDate: curDateTime,
        updatedAt: curDateTime,
      },
      { merge: true }
    );
    const fn = httpsCallable(cloudFuncs, "verifyUpdateUserDemographics");
    const response = await fn({});
  };
  return { completeSignup };
};
