import { checkActionCode } from "firebase/auth";
import { Link } from "react-router-dom";
import styles from "./NavbarButtonComponent.module.css";

const NavbarButtonComponent = ({ type, to, text, logout }) => {
  {
    if (type === "Logout")
      return (
        <div onClick={logout} className={styles["link-area-styles"]}>
          <Link to={to} className={styles["link-styles"]}>
            {text}
          </Link>
        </div>
      );
  }
  if (type === "Link") {
    return (
      <div className={styles["link-area-styles"]}>
        <Link to={to} className={styles["link-styles"]}>
          {text}
        </Link>
      </div>
    );
  }
};
export default NavbarButtonComponent;
