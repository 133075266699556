import SavedLocationItem from "./SavedLocationItem";

const Locations = ({ locations, user }) => {
  return (
    <div>
      {locations.map((location) => (
        <SavedLocationItem location={location} key={location.id} user={user} />
      ))}
    </div>
  );
};
export default Locations;
