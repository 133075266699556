import styles from "./DeliveryList.module.css";
import locationBlue from "../images/LocationBlue.svg";
import { format } from "date-fns";
import { useState } from "react";

const DeliveryLocationList = ({ j }) => {
  const [showDeliveryPhotos, setShowDeliveryPhotos] = useState(false);
  const handleViewDeliveryPhotos = (e) => {
    e.preventDefault();
    setShowDeliveryPhotos(!showDeliveryPhotos);
  };

  return (
    <div>
      <div key={j.sequenceNumber}>
        <div className={styles["delivery-grid"]}>
          <img src={locationBlue} alt="blue location icon" />
          <p>Delivery: {j.addressText}</p>
        </div>
        {j.deliveryReportedDate && (
          <>
            <div className={styles["delivery-flex"]}>
              <span className={styles["status-detail"]}>
                Delivered at
                {format(j.deliveryReportedDate, " MM/dd/yyyy pp")}
              </span>
              <div
                className={styles["status-logo"]}
                onClick={handleViewDeliveryPhotos}
              ></div>
            </div>
            {showDeliveryPhotos && (
              <img
                className={styles["status-photo"]}
                src={j.deliveryReportedImageURL}
                alt="picked up image"
              ></img>
            )}
          </>
        )}
      </div>
    </div>
  );
};
export default DeliveryLocationList;
