import { httpsCallable } from "firebase/functions";
import { cloudFuncs } from "../firebase/config";
import { useState } from "react";

export const usePaymentMethodDelete = (paymentMethodId) => {
  const [error, setError] = useState(false);
  const [paymentObj, setPaymentObj] = useState({});
  const deletePaymentMethod = async () => {
    let deleteErr = false;
    try {
      setError(false);
      const fn = httpsCallable(cloudFuncs, "deleteUserPaymentMethod");
      const results = await fn({ paymentMethodId });

      if (results) {
        setError(false);
        setPaymentObj(results);
      }
    } catch (err) {
      setError(true);
      deleteErr = true;
    }
    return deleteErr;
  };

  return { deletePaymentMethod, error };
};
