import HeadingTitle from "../components/HeadingTitle";
import ModalWindow from "../components/ModalWindow";
import { useAuthContext } from "../hooks/useAuthContext";
import { useLocationSubCollection } from "../hooks/useLocationSubCollection";
import Locations from "../components/Locations";
import Footer from "../components/Footer";
import styles from "./SavedLocations.module.css";
import { useNavigate } from "react-router-dom";

const SavedLocations = () => {
  const { user } = useAuthContext();
  const { documents: locations } = useLocationSubCollection(
    "users",
    user.uid,
    "locations"
  );
  const navigate = useNavigate();
  const handleNewLocation = () => {
    navigate("/addsavedlocation");
  };

  return (
    <>
      <div className="section">
        <HeadingTitle title="Saved Locations" />

        <ModalWindow>
          <div
            className={styles["btn-area-styles"]}
            onClick={handleNewLocation}
          >
            <div className={styles["add-new-location"]}>
              <p>Add</p>
              <div className={styles["add-icon"]}></div>
            </div>
          </div>

          {locations && locations.length > 0 && (
            <Locations locations={locations} user={user.uid} />
          )}
          {locations && locations.length === 0 && (
            <div className={styles.subheading}>
              <p>No saved locations found.</p>
            </div>
          )}
        </ModalWindow>
        <Footer />
      </div>
    </>
  );
};
export default SavedLocations;
