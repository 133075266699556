import stylesNew from "./DeliveryList.module.css";
import styles from "../pages/Deliveries.module.css";
import locationPink from "../images/LocationPink.svg";
import locationBlue from "../images/LocationBlue.svg";
import { format } from "date-fns";
import { useState } from "react";
import DeliveryLocationList from "./DeliveryLocationList";

const DeliveryList = ({ job }) => {
  const [showPickupPhotos, setShowPickupPhotos] = useState(false);
  const [showDeliveryPhotos, setShowDeliveryPhotos] = useState(false);
  const handleViewPickupPhotos = (e) => {
    e.preventDefault();
    setShowPickupPhotos(!showPickupPhotos);
  };
  const handleViewDeliveryPhotos = (e) => {
    e.preventDefault();
    setShowDeliveryPhotos(!showDeliveryPhotos);
  };

  return (
    <div key={job.id} className={styles["delivery-card"]}>
      <div className={stylesNew["delivery-date"]}>
        <p>{format(job.createdAt.toDate(), "MM/dd/yyyy pp")}</p>
      </div>

      <div className={stylesNew["delivery-grid"]}>
        <img src={locationPink} alt="pink location icon" />
        <p>Pickup: {job.pickupAddressText}</p>
      </div>
      {job.pickupDate && (
        <>
          <div className={stylesNew["delivery-flex"]}>
            <span className={stylesNew["status-detail"]}>
              Picked up at
              {format(job.pickupDate.toDate(), " MM/dd/yyyy pp")}
            </span>
            <div
              className={stylesNew["status-logo"]}
              onClick={handleViewPickupPhotos}
            ></div>
          </div>
          {showPickupPhotos && (
            <img
              className={stylesNew["status-photo"]}
              src={job.pickupImageURL}
              alt="picked up image"
            ></img>
          )}
        </>
      )}

      {job.deliveryAddressText && (
        <div>
          <div className={stylesNew["delivery-grid"]}>
            <img src={locationBlue} alt="blue location icon" />
            <p>Delivery: {job.deliveryAddressText}</p>
          </div>
          {job.deliveryReportedDate && (
            <>
              <div className={stylesNew["delivery-flex"]}>
                <span className={stylesNew["status-detail"]}>
                  Delivered at
                  {format(job.deliveryReportedDate.toDate(), " MM/dd/yyyy pp")}
                </span>
                <div
                  className={stylesNew["status-logo"]}
                  onClick={handleViewDeliveryPhotos}
                ></div>
              </div>
              {showDeliveryPhotos && (
                <img
                  className={stylesNew["status-photo"]}
                  src={job.deliveryReportedImageURL}
                  alt="picked up image"
                ></img>
              )}
            </>
          )}
        </div>
      )}

      {!job.deliveryAddressText &&
        job.deliveryLocations.map((j) => (
          <DeliveryLocationList j={j} key={j.sequenceNumber} />
        ))}

      <div className={stylesNew["delivery-flex"]}>
        <div className={stylesNew["price-code"]}>
          <p className={stylesNew.pfont}>{job.jobStatusCode}</p>
        </div>
        <div className={stylesNew["price-code"]}>
          <p className={stylesNew.pfont}>${job.price.toFixed(2)}</p>
        </div>
      </div>
    </div>
  );
};
export default DeliveryList;
