import { httpsCallable } from "firebase/functions";
import { cloudFuncs } from "../firebase/config";
import { useEffect, useState } from "react";

export const usePaymentMethod = () => {
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState("");
  const [paymentObj, setPaymentObj] = useState({});
  const fetchData = async () => {
    try {
      setIsPending(true);
      setError("");
      const fn = httpsCallable(cloudFuncs, "getUserPaymentMethods");
      const results = await fn();

      if (results) {
        setPaymentObj(results);
        setIsPending(false);
      }
    } catch (err) {
      setError(err.message);
      setIsPending(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return { paymentObj, fetchData, isPending, error };
};
