import { fireDB, cloudFuncs } from "../firebase/config";
import { httpsCallable } from "firebase/functions";
import { doc, setDoc } from "firebase/firestore";

export const useEditProfile = () => {
  const updateProfileInFirestore = async (
    userId,
    firstName,
    lastName,
    phoneNumber,
    email
  ) => {
    const curDateTime = new Date();
    const userDoc = doc(fireDB, "users", userId);

    const objVariable = {
      updatedAt: curDateTime,
      firstName,
      lastName,
      phoneNumber,
      email,
    };
    const setDocResp = await setDoc(userDoc, objVariable, { merge: true });
  };

  const updateEmail = async (email) => {
    const fn = httpsCallable(cloudFuncs, "updateUserEmailAddress");
    const response = await fn({ newEmailAddress: email });
  };

  const updatePhone = async (phoneNumber) => {
    const fn = httpsCallable(cloudFuncs, "updateUserPhoneNumber");
    const response = await fn({ newPhoneNumber: phoneNumber });
  };

  return { updateProfileInFirestore, updateEmail, updatePhone };
};
