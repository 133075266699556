import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { firebaseConfig } from "../env/env-config";

// init firebase
const app = initializeApp(firebaseConfig);

//init firestore development database
const fireDB = getFirestore(app);

// init firestore auth
const auth = getAuth(app);

// init firebase functions
const cloudFuncs = getFunctions(app);

// init project storage
const fireStorage = getStorage(app);

export { fireDB, auth, cloudFuncs, fireStorage };
