import { useQueryDeliveryCollection } from "../hooks/useQueryDeliveryCollection";
import { useAuthContext } from "../hooks/useAuthContext";
import { useState } from "react";
import HeadingTitle from "../components/HeadingTitle";
import ModalWindow from "../components/ModalWindow";
import DeliveryList from "../components/DeliveryList";
import Footer from "../components/Footer";
import styles from "./Deliveries.module.css";
const DeliveryHistory = () => {
  const { user } = useAuthContext();
  const [showPhotos, setShowPhotos] = useState(false);
  const sevenDays = 7 * 24 * 60 * 60 * 1000;
  const curDate = new Date();
  const weekPrior = new Date(curDate.getTime() - sevenDays);

  const {
    documents: jobs,
    error: error,
    count: count,
  } = useQueryDeliveryCollection(
    "jobs",
    ["requesterUID", "==", user.uid],
    ["jobStatusCode", "in", ["COMPLETE"]],
    ["createdAt", ">=", weekPrior],
    ["createdAt", "desc"]
  );
  const handleViewPickupPhotos = (e) => {
    e.preventDefault();
    setShowPhotos(!showPhotos);
  };

  return (
    <>
      <div className="section">
        <HeadingTitle title="Delivery History" />
        <div className="section">
          <ModalWindow>
            {(!jobs || jobs.length === 0) && (
              <div className={styles["delivery-card"]}>
                <h1>No deliveries completed in the last week.</h1>
              </div>
            )}

            {jobs && jobs.map((job) => <DeliveryList job={job} key={job.id} />)}
          </ModalWindow>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default DeliveryHistory;
