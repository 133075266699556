import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSavedLocation } from "../hooks/useSavedLocation";
import ModalWindow from "../components/ModalWindow";
import HeadingTitle from "../components/HeadingTitle";
import styles from "./EditSavedLocation.module.css";
import ButtonStyleComponent from "../components/ButtonStyleComponent";
import Footer from "../components/Footer";

const EditSavedLocation = () => {
  const location = useLocation();
  const [titleNew, setTitleNew] = useState(location.state.locationName);
  const [addressNew, setAddressNew] = useState(location.state.locationAddress);
  const [pending, setPending] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { editSavedLocation } = useSavedLocation();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setPending(true);
    try {
      await editSavedLocation(
        location.state.id,
        location.state.user,
        addressNew,
        titleNew
      );
      setPending(false);
      setError("");
      navigate("/savedlocations");
    } catch (err) {
      setError("Unable to save changes at this time. Please try again.");
      setPending(false);
    }
  };

  const handleCancel = () => {
    setPending(false);
    setError("");
    navigate("/savedlocations");
  };

  return (
    <>
      <div className="section">
        <HeadingTitle title="Edit Saved Locations" />

        <ModalWindow>
          <form className={styles["location-form"]}>
            <div>
              <label className={styles["location-form"]}>
                <span>Saved Location Title </span>
                <input
                  required
                  type="text"
                  onChange={(e) => setTitleNew(e.target.value)}
                  value={titleNew}
                />
              </label>
            </div>
            <div>
              <label className={styles["location-form"]}>
                <span>Saved Location Address </span>
                <textarea
                  required
                  rows="4"
                  onChange={(e) => setAddressNew(e.target.value)}
                  value={addressNew}
                />
              </label>
            </div>
            {pending && (
              <div className="pending">
                <p>Updating your saved location...</p>
              </div>
            )}
            {error && (
              <div className="errorMsg">
                <p>{error}</p>
              </div>
            )}

            <div className={styles["button-section"]}>
              {!pending && (
                <ButtonStyleComponent
                  buttonName="Update Location"
                  handleButton={handleSubmit}
                  buttonClass="update-location-btn"
                />
              )}
              {!pending && (
                <ButtonStyleComponent
                  buttonName="Cancel"
                  buttonClass="cancel-btn"
                  handleButton={handleCancel}
                />
              )}
            </div>
          </form>
        </ModalWindow>
        <Footer />
      </div>
    </>
  );
};
export default EditSavedLocation;
