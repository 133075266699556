import HeadingTitle from "../components/HeadingTitle";
import { usePaymentMethod } from "../hooks/usePaymentMethod";
import { useNavigate } from "react-router-dom";
import ModalWindow from "../components/ModalWindow";
import PaymentMethodItem from "../components/PaymentMethodItem";
import Footer from "../components/Footer";
import styles from "./PaymentMethod.module.css";

const PaymentMethod = () => {
  const { paymentObj, fetchData, isPending, error } = usePaymentMethod();
  const navigate = useNavigate();

  const onDelete = () => {
    fetchData();
  };
  const handleNewPayment = () => {
    navigate("/addpaymentmethod");
  };

  return (
    <>
      <HeadingTitle title="Manage Payment Methods"></HeadingTitle>
      <div className="section">
        <ModalWindow>
          <div className={styles["btn-area-styles"]} onClick={handleNewPayment}>
            <div className={styles["add-new-payment"]}>
              <p>Add</p>
              <div className={styles["add-icon"]}></div>
            </div>
          </div>
          {isPending && (
            <div className="pending">
              <p>Loading payment methods...</p>
            </div>
          )}
          {error && (
            <div className="errorMsg">
              <p>Unable to load payment methods at this time.</p>
              <p>Please try again in a few minutes.</p>
            </div>
          )}
          {!isPending &&
            !error &&
            paymentObj &&
            paymentObj.data &&
            paymentObj.data.length === 0 && (
              <div className={styles.subheading}>
                <p>No payment methods found.</p>
              </div>
            )}

          {!isPending &&
            !error &&
            paymentObj &&
            paymentObj.data &&
            paymentObj.data.map((data) => (
              <PaymentMethodItem
                key={data.id}
                data={data}
                onDelete={onDelete}
              />
            ))}
        </ModalWindow>
      </div>
      <Footer />
    </>
  );
};
export default PaymentMethod;
