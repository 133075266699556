import styles from "./Login.module.css";
import ModalWindow from "../components/ModalWindow";
import { Link, useNavigate } from "react-router-dom";
import { useLogin } from "../hooks/useLogin";
import { useState } from "react";
import ButtonStyleComponent from "../components/ButtonStyleComponent";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import HeadingTitle from "../components/HeadingTitle";
import Footer from "../components/Footer";

const Login = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showResetMsg, setShowResetMsg] = useState(false);
  const [resetError, setResetError] = useState(false);
  const [pending, setPending] = useState(false);
  const { error, login } = useLogin();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    const auth = getAuth();
    setShowResetMsg(true);
    sendPasswordResetEmail(auth, email)
      .then(() => {})
      .catch((error) => {
        setResetError(true);
      });
  };

  const handleSubmit = async (e) => {
    setResetError(false);
    setShowResetMsg(false);
    e.preventDefault();
    setPending(true);
    const errMsg = await login(email, password);

    if (!errMsg) {
      navigate("/dashboard");
    } else {
      setPending(false);
    }
  };

  return (
    <>
      <div className="section">
        <HeadingTitle title="Log In To GO-PHOR" />

        <ModalWindow>
          <form>
            <div className={styles["login-form"]}>
              <label className={styles["login-form"]}>
                <span>Email address: </span>
                <input
                  required
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  tabIndex="0"
                />
              </label>
            </div>
            <div className={styles["login-form"]}>
              <label className={styles["login-form"]}>
                <span>Password: </span>
                <input
                  required
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  tabIndex="0"
                />
              </label>
            </div>
            {error && (
              <div className="errorMsg">
                <p>Unable to log into your account. Please try again.</p>
              </div>
            )}

            {!(email && password) && !pending && (
              <div className={styles["button-item"]}>
                <ButtonStyleComponent
                  buttonName="Log In"
                  buttonClass="btn-disabled-login"
                  tabIndex="-1"
                />
              </div>
            )}

            <div className={styles["button-section"]}>
              {email && password && !pending && (
                <ButtonStyleComponent
                  buttonName="Log in"
                  handleButton={handleSubmit}
                  buttonClass="login-btn"
                />
              )}

              {pending && (
                <div className="pending">
                  <p>Logging in to your account...</p>
                </div>
              )}
            </div>
          </form>

          {email && (
            <div
              className={styles["reset-link"]}
              tabIndex="0"
              onClick={handleResetPassword}
            >
              Reset your password
            </div>
          )}
          {!email && (
            <div className={styles["reset-text"]} tabIndex="-1">
              <p>To reset password, enter in a valid email address.</p>
            </div>
          )}
          {showResetMsg && !resetError && (
            <div className={styles["reset-text"]}>
              <p>
                Check your email for a link to change your email password. Then
                enter your new email password to log in to your account.
              </p>
            </div>
          )}
          {showResetMsg && resetError && (
            <div className="errorMsg">
              <p>Unable to reset your password. Please try again.</p>
            </div>
          )}

          <Link to="/signup" className={styles["signup-link"]}>
            Sign up for a GO-PHOR account
          </Link>
        </ModalWindow>
        <Footer />
      </div>
    </>
  );
};

export default Login;
