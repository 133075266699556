import Footer from "../components/Footer";
import HeadingTitle from "../components/HeadingTitle";
import ModalWindow from "../components/ModalWindow";
import styles from "./PageNotFound.module.css";
const PageNotFound = () => {
  return (
    <>
      <HeadingTitle title="Page Not Found" />
      <ModalWindow>
        <div className={styles["not-found"]}>
          <p>
            The page you attempted to reach is not valid. Please use the buttons
            at the top to take you where you need to go.
          </p>
        </div>
      </ModalWindow>
      <Footer />
    </>
  );
};
export default PageNotFound;
