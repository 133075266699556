import styles from "./Signup.module.css";
import ModalWindow from "../components/ModalWindow";
import HeadingTitle from "../components/HeadingTitle";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSignup } from "../hooks/useSignup";
import { setIndexConfiguration } from "firebase/firestore";
import ButtonStyleComponent from "../components/ButtonStyleComponent";
import Footer from "../components/Footer";

/////////////////////////////////////////////////////////////////////
//                                                                 //
// Signup                                                          //
//                                                                 //
/////////////////////////////////////////////////////////////////////
const Signup = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { signup } = useSignup();
  const [pending, setPending] = useState(false);
  const [password2, setPassword2] = useState("");

  /////////////////////////////////////////////////////////////////////
  //                                                                 //
  // handleSubmit                                                    //
  //                                                                 //
  /////////////////////////////////////////////////////////////////////
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    if (checkPasswords()) {
      setPending(true);
      try {
        await signup(email, password);
        navigate("/signup2");
      } catch (err) {
        setPending(false);
        setError("Unable to create new account. Please try again.");
      }
    }
  };
  const disabledBtn = () => {};

  /////////////////////////////////////////////////////////////////////
  //                                                                 //
  // checkPasswords                                                  //
  //                                                                 //
  // Passwords must be at least 6 characters or longer and the two   //
  // passwords must match. Then the passwords are considered valid.  //
  //                                                                 //
  // returns valid, which is either true or false                    //
  //                                                                 //
  /////////////////////////////////////////////////////////////////////
  const checkPasswords = () => {
    let valid = false;
    if (password === password2 && password.length > 5) {
      valid = true;
    } else if (password === password2 && password.length < 5) {
      setError(
        "Invalid passwords. Passwords must be at least 6 characters long."
      );
    } else if (password !== password2) {
      setError("Invalid passwords. Passwords must match.");
    }
    return valid;
  };
  return (
    <>
      <div className="section">
        <HeadingTitle title="Sign Up For A GO-PHOR Account"></HeadingTitle>
        <ModalWindow>
          <form className={styles["signup-form"]}>
            <div>
              <label className={styles["signup-form"]}>
                <span>Email address: </span>
                <input
                  required
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </label>
            </div>
            <div>
              <label className={styles["signup-form"]}>
                <span>Password: </span>
                <input
                  required
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
              </label>
            </div>
            <div>
              <label className={styles["signup-form"]}>
                <span>Confirm password: </span>
                <input
                  required
                  type="password"
                  onChange={(e) => setPassword2(e.target.value)}
                  value={password2}
                />
              </label>
            </div>
            {pending && (
              <div className="pending">
                <p>Creating account...</p>
              </div>
            )}
            {error && (
              <div className="errorMsg">
                <p>{error}</p>
              </div>
            )}

            <div className={styles["button-section"]}>
              {!pending && email && password2 && password && (
                <div className={styles["button-item"]}>
                  <ButtonStyleComponent
                    buttonName="Create Account"
                    handleButton={handleSubmit}
                    buttonClass="signup-btn"
                  />
                </div>
              )}
              {!(email && password2 && password) && !pending && (
                <div className={styles["button-item"]}>
                  <ButtonStyleComponent
                    buttonName="Create Account"
                    handleButton={disabledBtn}
                    buttonClass="btn-disabled"
                    tabIndex="-1"
                  />
                </div>
              )}
            </div>
          </form>

          <Link to="/login" className={styles["login-link"]}>
            Log in to your GO-PHOR account
          </Link>
        </ModalWindow>
        <Footer />
      </div>
    </>
  );
};

export default Signup;
