import { httpsCallable } from "firebase/functions";
import { cloudFuncs } from "../firebase/config";

export const useStripePaymentMethods = () => {
  const getKey = async () => {
    const fn = httpsCallable(
      cloudFuncs,
      "getUserSecretKeyForPaymentMethodSetup"
    );
    const response = await fn({});
    const clientSecret = response.data.clientSecret;

    return clientSecret;
  };
  return { getKey };
};
